<template>
<div class="grid">
  <div class="col-12">
    <div class="card">
      <Toast />
      <Toolbar class="mb-4">
        <template v-slot:start>
          <div class="my-2">
            <Button label="Nova Unidade" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
            <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
              :disabled="!selectedProducts || !selectedProducts.length" />
          </div>
        </template>

        <template v-slot:end>
          <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
          <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
        </template>
      </Toolbar>

      <DataTable exportFilename="relatorio_iptu[UNIDADES]" csvSeparator=";" ref="dt" :value="unidades"
        v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="50" :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
        responsiveLayout="scroll">
        <template #header>
          <div class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              ">
            <h5 class="m-0">Gereneciar Unidades</h5>
            <span class="flex flex-row align-items-center mt-2 md:mt-0 p-input-icon-left gap-3">
              <i class="pi pi-search" />
              <Dropdown v-model="filters['id_empresa'].value" :options="empresas" showClear optionLabel="label"
                optionValue="value" placeholder="Selecione uma empresa" :filter="true">
              </Dropdown>
              <InputText v-model="filters['global'].value" placeholder="Buscar" />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">Id</span>
            {{ slotProps.data.id }}
          </template>
        </Column>
        <Column field="inscricao_imobiliaria" header="Inscrição Imobiliaria" :sortable="true"
          headerStyle="width:30%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">inscricao_imobiliaria</span>
            {{ slotProps.data.inscricao_imobiliaria }}
          </template>
        </Column>
        <Column field="ordem_sap" header="Ordem SAP" :sortable="true" headerStyle="width:30%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title"></span>
            {{ slotProps.data.ordem_sap }}
          </template>
        </Column>
        <Column field="controle_re" header="Contrato RE" :sortable="true" headerStyle="width:30%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title"></span>
            {{ slotProps.data.controle_re }}
          </template>
        </Column>
        <Column headerStyle="min-width:10rem;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
              @click="editProduct(slotProps.data)" />
            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
              @click="apagarUnidadeModal(slotProps.data)" />
          </template>
        </Column>
      </DataTable>

      <Dialog v-model:visible="productDialog" :style="{ width: '900px' }" header="Gerenciar Unidade" :modal="true"
        class="p-fluid">
        <div class="grid">
          <div class="col-3">
            <div class="field">
              <label for="razao_social">Empresa</label>
              <Dropdown id="status" v-model="unidade.id_empresa" :options="empresas" optionLabel="label"
                optionValue="value" placeholder="Selecione uma empresa">
              </Dropdown>
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="contrato_re">Contrato RE</label>
              <InputText id="controle_re" v-model.trim="unidade.controle_re" required="true" autofocus
                :class="{ 'p-invalid': submitted && !unidade.controle_re }" />
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="contrato_re">Inscrição Imobiliaria</label>
              <InputText id="inscricao_imobiliaria" v-model.trim="unidade.inscricao_imobiliaria" required="true"
                autofocus :class="{
                  'p-invalid': submitted && !unidade.inscricao_imobiliaria,
                }" />
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="ordem_sap">Ordem SAP</label>
              <InputText id="ordem_sap" v-model.trim="unidade.ordem_sap" required="true" autofocus
                :class="{ 'p-invalid': submitted && !unidade.ordem_sap }" />
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="ordem_sap">CNPJ Unidade</label>
              <InputText id="cnpj" v-model.trim="unidade.cnpj_unidade" autofocus />
            </div>
          </div>
          <div class="col-9">
            <div class="field">
              <label for="ordem_sap">Observação</label>
              <InputText id="observacao" v-model.trim="unidade.observacao" autofocus />
            </div>
          </div>
        </div>
        <hr />
        <div class="grid">
          <div class="col-3">
            <div class="field">
              <label for="endereco">Endereço</label>
              <InputText id="endereco" v-model.trim="unidade.endereco" required="true" autofocus
                :class="{ 'p-invalid': submitted && !unidade.endereco }" />
            </div>
          </div>
          <div class="col-2">
            <div class="field">
              <label for="numero">Número</label>
              <InputText id="numero" v-model.trim="unidade.numero" autofocus />
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="complemento">Complemento</label>
              <InputText id="complemento" v-model.trim="unidade.complemento" autofocus />
            </div>
          </div>

          <div class="col-4">
            <div class="field">
              <label for="bairro">Bairro</label>
              <InputText id="bairro" v-model.trim="unidade.bairro" required="true" autofocus
                :class="{ 'p-invalid': submitted && !unidade.bairro }" />
            </div>
          </div>
          <div class="col-4">
            <div class="field">
              <label for="cep">CEP</label>
              <InputMask id="cep" v-model.trim="unidade.cep" required="true" autofocus mask="99999-999"
                :class="{ 'p-invalid': submitted && !unidade.cep }" />
            </div>
          </div>
          <div class="col-4">
            <div class="field">
              <label for="estados">Estado</label>
              <Dropdown id="estados" v-model="unidade.id_estado" :options="estados" optionLabel="label"
                placeholder="Selecione um estado" optionValue="value" @change="trocarCidades()" :filter="true">
              </Dropdown>
            </div>
          </div>

          <div class="col-4">
            <div class="field">
              <label for="cidade">Cidade</label>
              <Dropdown id="status" v-model="unidade.id_cidade" :options="cidades" optionLabel="label"
                optionValue="value" placeholder="Selecione uma cidade" :filter="true">
              </Dropdown>
            </div>
          </div>
        </div>
        <hr />
        <div class="grid">
          <div class="col-3">
            <div class="field">
              <label for="estado">Área</label>
              <Dropdown id="status" v-model="unidade.id_area" :options="areas" optionLabel="label" optionValue="value"
                placeholder="Selecione uma empresa">
              </Dropdown>
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="estado">Tipo</label>
              <Dropdown id="status" v-model="unidade.id_tipo" :options="tipos" optionLabel="label"
                placeholder="Selecione uma empresa" optionValue="value">
              </Dropdown>
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="estado">Regional</label>
              <Dropdown id="status" v-model="unidade.id_regional" :options="regionais" optionLabel="label"
                placeholder="Selecione uma empresa" optionValue="value">
              </Dropdown>
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="razao_social">Prioridade</label>
              <Dropdown id="status" v-model="unidade.prioridade" :options="prioridades" optionLabel="label"
                optionValue="value" placeholder="Prioridade">
              </Dropdown>
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="complemento">Regra Pagamento</label>
              <!--
              <Dropdown id="status" v-model="unidade.regra_pagamento" :options="regras" optionLabel="label"
                placeholder="Selecione uma regra" optionValue="value">
              </Dropdown>
            -->
              <Dropdown id="status" v-model="unidade.id_tipo_regra_pgto" :options="regrasPgtos" optionLabel="label"
                placeholder="Selecione uma regra" optionValue="value">
              </Dropdown>
            </div>

          </div>
          <div class="col-2">
            <div class="field">
              <label for="complemento">Data Desativação</label>
              <InputMask id="data_desativacao" v-model.trim="unidade.data_desativacao" autofocus mask="99/99/9999" />
            </div>

          </div>
        </div>
        <hr />
        <div class="grid">
          <div class="col-3">
            <div class="field">
              <label for="complemento">CNPJ</label>
              <InputMask id="complemento" v-model.trim="unidade.cnpj" autofocus mask="99.999.999/9999-99" />
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="complemento">Site</label>
              <InputText id="complemento" v-model.trim="unidade.site" autofocus />
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="complemento">Login</label>
              <InputText id="complemento" v-model.trim="unidade.login" autofocus />
            </div>
          </div>
          <div class="col-3">
            <div class="field">
              <label for="complemento">Senha</label>
              <InputText id="complemento" v-model.trim="unidade.senha" autofocus />
            </div>
          </div>
        </div>

        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
          <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarUnidade" />
        </template>
      </Dialog>

      <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true" header="&nbsp;">
        <div class="flex align-items-center justify-content-center">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="unidade">Apagar: <b>{{ unidade.inscricao_imobiliaria }}</b>?</span>
        </div>
        <template #footer>
          <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm"
            @click="deleteProductDialog = false" />
          <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarUnidade" />
        </template>
      </Dialog>

      <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
        <div class="flex align-items-center justify-content-center">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="unidades">Apagar unidades selecionadas?</span>
        </div>
        <template #footer>
          <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
          <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
        </template>
      </Dialog>
    </div>
  </div>
</div>
</template>

<script>
import { FilterMatchMode } from "primevue/api"

export default {
  data() {
    return {
      regras: [
        { label: "Pagamento por guia", value: "1" },
        { label: "Pagamento por guia da prefeitura", value: "2" },
      ],
      prioridades: [
        { label: "0", value: 0 },
        { label: "1", value: 1 },
        { label: "2", value: 2 }
      ],
      prioridade: "",
      unidades: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      unidade: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: 0,
      empresas: [],
      areas: [],
      regionais: [],
      regrasPgtos: [],
      tipos: [],
      estados: [],
      cidades: [],
      id_estado: "",
      id_cidade: "",
      cnpj_unidade: "",
      observacao: "",
      statuses: [
        { label: "Inativo", value: "0" },
        { label: "Ativo", value: "1" },
      ],
    }
  },
  productService: null,
  created() {
    this.initFilters()
  },
  mounted() {
    this.listarEmpresa()
    this.listarAreas()
    this.listarRegionais()
    this.listarRegrasPgtos()
    this.listarTipos()
    this.listarEstados()

    this.listarUnidade()
    //this.productService.getProducts().then((data) => (this.products = data));
  },
  methods: {
    listarAreas() {
      this.axios
        .post("/areas/listar")
        .then((response) => {
          let options = []
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["titulo"] }
            options.push(option)
          })
          this.areas = options
        })
        .catch((error) => {
          console.log(error)
        })
    },
    listarRegionais() {
      this.axios
        .post("/regional/listar")
        .then((response) => {
          let options = []
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["titulo"] }
            options.push(option)
          })
          this.regionais = options
        })
        .catch((error) => {
          console.log(error)
        })
    },
    listarRegrasPgtos() {
      this.axios
        .post("/regra-pgto/listar")
        .then((response) => {
          let options = []
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["titulo"] }
            options.push(option)
          })
          this.regrasPgtos = options
        })
        .catch((error) => {
          console.log(error)
        })
    },
    trocarCidades() {
      console.log(this.unidade.id_estado)
      this.listarCidades(this.unidade.id_estado)
    },
    listarCidades() {
      let data = {
        state_id: this.unidade.id_estado,
      }
      this.axios
        .post("/auxiliar/cidades", data)
        .then((response) => {
          let options = []
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["title"] }
            options.push(option)
          })
          this.cidades = options
          let set = this.unidade.id_cidade
          this.unidade.id_cidade = set
        })
        .catch((error) => {
          console.log(error)
        })
    },
    listarEstados() {
      this.axios
        .post("/auxiliar/estados")
        .then((response) => {
          let options = []
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["letter"] }
            options.push(option)
          })
          this.estados = options
          this.id_estado = 1
        })
        .catch((error) => {
          console.log(error)
        })
    },
    listarTipos() {
      this.axios
        .post("/tipos/listar")
        .then((response) => {
          let options = []
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["titulo"] }
            options.push(option)
          })
          this.tipos = options
        })
        .catch((error) => {
          console.log(error)
        })
    },
    listarEmpresa() {
      this.axios
        .post("/empresas/listar")
        .then((response) => {
          let options = []
          response.data.dados.map((el) => {
            options.push({ value: el["id"], label: el["razao_social"] })
          })
          this.empresas = options
        })
        .catch((error) => {
          console.error(error)
        })
    },
    listarUnidade() {
      this.axios
        .post("/unidades/listar")
        .then((response) => {
          this.unidades = response.data.dados
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          })
        })
    },
    trocarStatus() {
      this.unidade.status = this.status.value
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      return
    },
    openNew() {
      this.unidade = {}
      this.submitted = false
      this.productDialog = true
    },
    hideDialog() {
      this.productDialog = false
      this.submitted = false
    },
    salvarUnidade() {
      this.submitted = true
      if (this.unidade.id) {
        this.axios
          .post("/unidades/editar", this.unidade)
          .then(() => {
            this.listarUnidade()
            this.productDialog = false
            this.unidade = {}
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            })
          })
      } else {
        this.axios
          .post("/unidades/adicionar", this.unidade)
          .then(() => {
            this.listarUnidade()
            this.productDialog = false
            this.unidade = {}
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            })
          })
      }
    },
    editProduct(unidade) {
      this.productDialog = true

      this.unidade = { ...unidade }
      this.listarCidades(this.unidade.id_estado)
    },
    apagarUnidadeModal(unidade) {
      this.unidade = unidade
      this.deleteProductDialog = true
    },
    apagarUnidade() {
      this.axios
        .post("/unidades/apagar", this.unidade)
        .then(() => {
          this.listarUnidade()
          this.deleteProductDialog = false
          this.unidade = {}
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Unidade apagada com sucsso!",
            life: 3000,
          })
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          })
        })
    },
    findIndexById(id) {
      let index = -1
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i
          break
        }
      }
      return index
    },
    createId() {
      let id = ""
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return id
    },
    exportCSV() {
      alert(this.selectedProducts)
      this.$refs.dt.exportCSV()
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true
    },
    deleteSelectedProducts() {
      let obj = this.selectedProducts
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/unidades/apagar", obj[key])
          .then(() => {
            this.listarUnidade()
            this.deleteProductDialog = false
            this.unidade = {}
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usário apagado com sucesso!s",
              life: 3000,
            })
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            })
          })
      })

      this.deleteProductsDialog = false
      this.selectedProducts = null
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Unidades apagada com sucesso!",
        life: 3000,
      })
    },
    initFilters() {
      this.filters = {
        id_empresa: { value: null, matchMode: FilterMatchMode.EQUALS },
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
@use "../../assets/demo/badges.scss";
</style>
